let Config = {
  API:'https://payapi.snappywire.com:5000',
  //API:'http://localhost:5000',
  MinInvoice:25,
  gtag:'AW-00000000',
  Company:'SnappyWire',
  Website:'https://snappywire.com',
  WidgetUrl:'https://payapps.snappywire.com/widget/snappywire.js?v4',
  WidgetName:'snappywire',
  VerifyUrl:'https://snappywire.com/merchant_verify/',
  POSUrl:'https://pos.snappywire.com/',
  SignupUrl:'/#/a/register-page',
  ShowSignup:true
};
module.exports = Config;