import React, { Component } from "react";
import { Breadcrumb,Grid, Col, Row } from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import StatsCard from "components/Card/StatsCard.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
const Config = require('config');


class Customers extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  loading: true,
                customerdatalist:[],
                owed:"",
                paid:"",
                kyc:false
                  
                }
               
              //  console.log('this.props', this.props)
            }
    

            
componentWillMount(){

    this.getCustomers();

    var user=JSON.parse(localStorage.getItem('user'));

    if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
this.setState({kyc:false})}
  }
  
  getCustomers(){

    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var token=user.token;

    const requestOptions = {
      headers: {'access-token': token,'mid':user.data.merchant_id},
   };
      


    fetch(Config.API+'/api/customers/',requestOptions)
    .then( this.handleResponse)
    .then( customersdata => this.setState({ customerdatalist:customersdata.data, loading: false}))
   

  }




  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
console.log(data)
          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}

  
newcustomer(){ window.location.hash = "#/billing/customers/new"};

  

  render() {
    const {kyc} = this.state; 
    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
          
        <Grid fluid>
  
          <Row>
        
          <Col lg={12}>  <h3>Customers</h3> </Col>
      <Breadcrumb>
            <Breadcrumb.Item href="/#/billing">Billing</Breadcrumb.Item>
            <Breadcrumb.Item active>Customers</Breadcrumb.Item>
          </Breadcrumb>
<Col lg={12}>

          <div className="card">
         
             
<div className="content">
{kyc==true?<Button  bsStyle="default"  onClick={() => this.newcustomer()}>
                      New
                        <span className="btn-label btn-label-right">
                        <i class="fas fa-user"></i>
                        </span>
                      </Button>
:<div></div>}
                      <ReactTable
                  
                  data={this.state.customerdatalist}
                  filterable
                  columns={[
                    {
                      Header: "Display Name",
                      accessor:"display_name",
                     
                      
                    },
                    
                    {
                      Header: "Company",
                      accessor:"company",
                     
                      
                    },
                    
                    {
                      Header: "First Name",
                      accessor:"firstname",
                     
                      
                    },
                    
                    {
                      Header: "Last Name",
                      accessor:"lastname",
                     
                      
                    },
                    {
                      Header: "Actions",
                      width: 200,
                      filterable: false,
                      sortable: false,
                      Cell: row => (
                        <div className="actions-right">
                             <Button
                             
                             onClick={() => window.location.hash = "#/billing/customers/"+row.original.customer_id}
  bsStyle="info"
  simple
  icon
  ><i className="fal fa-edit"></i> Edit</Button>{" "}
                        </div>



                    )}
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  noDataText= "No Customers Found"
                />
              

          </div>
          </div></Col>
          </Row>
        </Grid>
      </div>









    );
  }
}

export default Customers;