import React, { Component } from "react";
import { Grid, Col, Row} from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
const Config = require('config');


class Settlement extends Component {




    constructor(props){
        super(props);

        
                this.state = {
                  loading: true,
                paymentsdatalist:[],
                owed:"",
                paid:"",
                owedcad:"",
                paidcad:"",
                owedeur:"",
                paideur:"",
                  mid:"",
                  kyc:false
                }
               
              //  console.log('this.props', this.props)
            }
                
componentDidMount(){
//this.getPaymentsTotals();
    this.getPayments();
    var user=JSON.parse(localStorage.getItem('user'));
          
    if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
this.setState({kyc:false})}

  }
  
  getPayments(){

    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var token=user.token;

    const requestOptions = {
      headers: {'access-token': token,'mid':user.data.merchant_id},
   };
      


    fetch(Config.API+'/api/payments',requestOptions)
    .then( this.handleResponse)
    .then( paymentdata => this.setState({ paymentsdatalist:paymentdata.data, loading: false,mid:mid}))
   

  }




  export(){ 
    const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        filename:'Payments',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: false,
        title: '',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ['Net Amount Paid ( Minus Payment Fees )','Commission Fees Paid','Date Time','Currency','Payment Fee','Payment Type','Notes']
      };
     
    const csvExporter = new ExportToCsv(options);
  
      


   csvExporter.generateCsv(this.state.paymentsdatalist)

      

  }

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}


  

  render() {
    if(this.state.paid==null){this.setState({ paid:0})};
    if(this.state.paidcad==null){this.setState({ paidcad:0})};
    if(this.state.paideur==null){this.setState({ paideur:0})};
    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
          
        <Grid fluid>
          <Row>

        
     
   
<Col lg={12}>

          <div className="card">
          <h5 className="header">Settlement History</h5>
             

          <div className="content">
          <Button  bsStyle="default" onClick={() => this.export()}>
                       Export
                        <span className="btn-label btn-label-right">
                           <i className="fa fa-download " />
                        </span>
                      </Button>

                      <ReactTable
                  
                  data={this.state.paymentsdatalist}
                  filterable
                  columns={[
                    {
                      Header: "Date",
                      accessor:"date",
                     
                      Cell: props => {
                        return <Moment format="YYYY-MM-D H:mm:ss">{props.value}</Moment>;
                      },
                    },
                    {
                     
                      filterable: false,
                      Header: "Currency",
                      accessor:"currency"}
                      ,
                    {
                      Header: "Net Amount Paid ( Minus Payment/ Network Fees )",
                      id: "amount_paid",
                      accessor:"amount_paid"    }
                      ,
                      {
                        Header: "Commission Fees Paid",
                        id: "fee_amount",
                        accessor:"fee_amount"    },
                        {
                          Header: "Payment Fee",
                          id: "payment_type_fee",
                          accessor:"payment_type_fee"    },
                          {
                            Header: "Payment Fee Type",
                            id: "payment_type",
                            accessor:"payment_type"    },
                            {
                              Header: "Notes",
                              id:"notes",
                              accessor:"notes"    }
                      
                    
                    
                  ]}
                  noDataText= "No Payments Found"
                  defaultPageSize={10}

                  showPaginationBottom={true}
                  className="-striped -highlight"
                  
                />
              

          </div>
          </div></Col>
          </Row>
        </Grid>
      </div>









    );
  }
}

export default Settlement;