import Dashboard from "views/Dashboard/Dashboard.jsx";
import Settings from "views/Settings/Settings.jsx";
import BankingInfo from "views/Settings/banking-info.jsx";
import AccountInfo from "views/Settings/account-info.jsx";
import PayNowWidget from "views/Settings/paynowwidget.jsx";
import Profile from "views/Profile/profile.jsx";
import Payments from "views/Payments/payments.jsx";
import Settlements from "views/Settlement/settlement.jsx";
import Addresses from "views/Addresses/addresses.jsx";
import Transactions from "views/Transactions/transactions.jsx";
import  ApiInfo from "views/Settings/api-info.jsx";
import  ApiKeys from "views/Settings/apis.jsx";
import  Billing from "views/Billing/billing.jsx";
import  Invoices from "views/Billing/invoices.jsx";
import  InvoiceInfo from "views/Billing/invoice-info.jsx";
import  Customers from "views/Billing/customers.jsx";
import  CustomerInfo from "views/Billing/customers-info.jsx";
import POS from "views/Settings/pos.jsx";



import pagesRoutes from "./pages.jsx";


var dashboardRoutes = [
  {
 
    path: "/account_balances",
    name: "Account Balances",
    icon: "fal fa-balance-scale",
    component: Payments
   
  },{
 
    path: "/settlement_history",
    name: "Settlement History",
    icon: "fal fa-file-invoice-dollar",
    component: Settlements
   
  },{
 
    path: "/payments_received",
    name: "Payments Received",
    icon: "fal fa-hand-holding-usd",
    component: Transactions
   
   
  },{
 
    path: "/addresses",
    name: "Addresses",
    icon: "fal fa-wallet",
    component: Addresses
   
  },
      {
        menuhide:true,
        path: "/profile",
        name: "Profile",
        component: Profile
      },

      {
        menuhide:true,
        path: "/billing/invoices/:invid",
        name: "Invoice Info",
        icon: "fal fa-file-invoice-dollar",
        component: InvoiceInfo
       
      },
{
        menuhide:true,
        path: "/billing/customers/:clid",
        name: "Customer Info",
        icon: "fal fa-file-invoice-dollar",
        component: CustomerInfo
       
      },
      {
        menuhide:true,
        path: "/billing/invoices",
        name: "Invoices",
        icon: "fal fa-file-invoice-dollar",
        component: Invoices
       
      },

      ,


      {
       menuhide:true,
       path: "/billing/pos",
       name: "POS",
       icon: "far fa-credit-card-blank",
       component: POS
      
     },
      

      {
        menuhide:true,
        path: "/billing/customers",
        name: "Customers",
        icon: "fal fa-file-invoice-dollar",
        component: Customers
       
      },

      {
        menuhide:false,
        path: "/billing",
        name: "Billing",
        icon: "fal fa-file-invoice-dollar",
        component: Billing
       
      },
     
   

      
      
  { redirect: true, path: "/", pathTo: "/account_balances", name: "Home" }


];
export default dashboardRoutes;
