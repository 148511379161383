import React, { Component } from "react";
import { Grid, Col, Row} from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import PaymentsCard from "components/Card/PaymentsCard.jsx";
import Loading from "components/Loader/loading.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
const Config = require('config');


class Payments extends Component {




    constructor(props){
        super(props);

        
                this.state = {
                  loading: true,
                  paymentsdatalist:[],
                owed:"",
                paid:"",
                owedcad:"",
                paidcad:"",
                owedeur:"",
                paideur:"",
                  mid:"",
                  kyc:false
                }
               
              //  console.log('this.props', this.props)
            }
                
componentDidMount(){
//this.getPaymentsTotals();
this.getBalances()
    var user=JSON.parse(localStorage.getItem('user'));
          
    if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
this.setState({kyc:false})}

  }
  

  getBalances(){
    this.setState({ downloading: true},()=>{
    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var token=user.token;


    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'  ,'access-token': token,"mid":mid}
   };
      


    fetch(Config.API+'/api/balances',requestOptions)
    .then( this.handleResponse)
    .then( withdrawdata => this.setState({ paymentsdatalist:withdrawdata.data, downloading: false,mid:mid},()=>{if(this.state.paymentsdatalist!=''){
      this.export()
        }else{    this.setState({ paymentsdatalist:[{ coin: "", balance: 0 }]},()=>{this.export()})}
  
      }))
   
  })
  }

  export(){ 


    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      filename:'Balances',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      title: '',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ['Coin','Balance']
    };
   
  const csvExporter = new ExportToCsv(options);


csvExporter.generateCsv(this.state.paymentsdatalist)

    


}

  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{

          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}


  

  render() {
    const { downloading,paymentsdatalist} = this.state;
    if(this.state.paid==null){this.setState({ paid:0})};
    if(this.state.paidcad==null){this.setState({ paidcad:0})};
    if(this.state.paideur==null){this.setState({ paideur:0})};

    return (
         <div className="main-content">
          
        <Grid fluid>
          <Row>

          <Col lg={12}> {this.state.kyc!=true?<Card 
      warning="true"
                  content={
                  <Row >
                    <Col  className="text-center">
                    
                    <i className="fas fa-exclamation-triangle"></i> Please <a  href={Config.VerifyUrl} target="_blank">Verify</a> your account. <a href={Config.VerifyUrl} target="_blank">Verify Now</a>
    
              
                   
                    </Col>
                  
                  </Row>
                }
              />:<div></div>}</Col>
          <Col lg={12}>  <h3>Account Balances</h3>
        <div>{!downloading
                 ?<div className="options"> <Button  bsStyle="default" onClick={() => this.export()}>
                              Export
                               <span className="btn-label btn-label-right">
                                  <i className="fa fa-download " />
                               </span>
                             </Button>
                             </div>
                             :<div className="options"><Loading /> Exporting</div>}</div>
    </Col>
     <Col lg={6}>
       <PaymentsCard
         currency="USD"
                statsText="Payable Owed to Merchant"
    
              mid={this.state.mid}
      
              /> </Col>    


<Col lg={6}>            <PaymentsCard
          currency="CAD"
         statsText="Payable Owed to Merchant"
 
         mid={this.state.mid}
 
       /> </Col>   
<Col lg={12}> </Col>
<Col lg={6}>            <PaymentsCard
         currency="EUR"
         statsText="Payable Owed to Merchant"
 
         mid={this.state.mid}

       /> </Col>  
       <Col lg={6}>            <PaymentsCard
         currency="BTC"
         statsText="Payable Owed to Merchant"
    
         mid={this.state.mid}

       /> </Col>   

<Col lg={12}> </Col>
<Col lg={6}>            <PaymentsCard
         currency="BCH"
         statsText="Payable Owed to Merchant"
         processButton="true"
         mid={this.state.mid}

       /> </Col>  
       <Col lg={6}>            <PaymentsCard
         currency="LTC"
         statsText="Payable Owed to Merchant"
      
         mid={this.state.mid}

       /> </Col>   
<Col lg={12}> </Col>

       <Col lg={6}>            <PaymentsCard
         currency="ETH"
         statsText="Payable Owed to Merchant"
    
         mid={this.state.mid}

       /> </Col>  
              <Col lg={6}>            <PaymentsCard
         currency="DOGE"
         statsText="Payable Owed to Merchant"
      
         mid={this.state.mid}

       /> </Col>   
<Col lg={12}> </Col>
<Col lg={6}>            <PaymentsCard
         currency="USDT"
         statsText="Payable Owed to Merchant"
   
         mid={this.state.mid}

       /> </Col>  

<Col lg={6}>            <PaymentsCard
         currency="USDC"
         statsText="Payable Owed to Merchant"
         processButton="true"
         mid={this.state.mid}

       /> </Col>  


<Col lg={12}>  </Col>
<Col lg={6}>            <PaymentsCard
         currency="USDT_TRON"
         statsText="Payable Owed to Merchant"
         processButton="true"
         mid={this.state.mid}

       /> </Col>  

<Col lg={6}>            <PaymentsCard
         currency="DASH"
         statsText="Payable Owed to Merchant"
         processButton="true"
         mid={this.state.mid}

       /> </Col>  

       <Col lg={12}>  </Col>
<Col lg={6}>            <PaymentsCard
         currency="XRP"
         statsText="Payable Owed to Merchant"
         processButton="true"
         mid={this.state.mid}

       /> </Col>  

<Col lg={6}>            <PaymentsCard
         currency="SOL"
         statsText="Payable Owed to Merchant"
         processButton="true"
         mid={this.state.mid}

       /> </Col>   
   
   <Col lg={6}>            <PaymentsCard
         currency="BNB"
         statsText="Payable Owed to Merchant"
         processButton="true"
         mid={this.state.mid}

       /> </Col>  
          </Row>
        </Grid>
      </div>









    );
  }
}

export default Payments;