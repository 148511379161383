import React, { Component } from "react";
import { Grid, Row, Col, Media, FormControl, FormGroup } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Card from "components/Card/Card.jsx";
import Reaptcha from 'reaptcha';
import Button from "components/CustomButton/CustomButton.jsx";
import gtag, { install } from 'ga-gtag';
import logo from "snappywireb.svg";
const Config = require('config');
install(Config.gtag);
class RegisterPage extends Component {


  constructor(props,context) {
    super(props,context);

    this.state = {
      cardHidden: true,
      verified: false,
      submitted: false,
      created: false,
      error: '',
   message:'',
      firstnameError:null,
      lastnameError:null,
      companyError:null,
      emailError:null,
      passwordError:null,
      password2Error:null,
      firstname:"",
      lastname:"",
      email:"",
      company:"",
      password:"",
      password2:"",
      alert: null,
      show: false,
      reCaptchaError:null,
      disabledbtn:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit= this.handleSubmit.bind(this);
this.handleValidate = this.handleValidate.bind(this);
this.hideAlert = this.hideAlert.bind(this);


  }


  componentDidMount() {
    if (this.captchaChack) {
       
        this.captchaChack.reset();

    }
  }

  onVerify = recaptchaResponse => {
    this.setState({
      verified: true
    });
  };





  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
}


 async handleSubmit(e) {
  const {  firstname,lastname,company,email,password} = this.state;
  await this.handleValidate();
this.setState({disabledbtn:true},
  function(){
  this.createaccount(email,password,firstname,lastname,company) }
  )
  }

  
 

createaccount(email,password,firstname,lastname,company){

  if( this.state.reCaptchaError === null && this.state.passwordError === null  && this.state.password2Error === null && this.state.companyError === null && this.state.firstnameError === null && this.state.lastnameError === null){

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify({ email,password,firstname,lastname,company})
    };
        
  
return fetch(Config.API+`/createaccount`,requestOptions)
.then( this.handleResponse)
   .then( account => this.setState({created:true,message:account.data.message},this.messagealert(account.data.message)))
  

  }else{this.setState({disabledbtn:false})}
}


successAlert() {
  this.setState({
    alert: (
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Your account has been created!"
        onConfirm={() => window.location.href = "/"}
    
        confirmBtnBsStyle="info"
      >
        Please Sign in!
      </SweetAlert>
    )
  });
}

existAlert() {
  this.setState({
    alert: (
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={'An account already exists for '+this.state.email}
        onConfirm={() => window.location.href = "/"}
   
        confirmBtnBsStyle="info"
      >
       Please Sign in!
      </SweetAlert>
    )
  });
}

hideAlert() {
  this.setState({
    alert: null
  });
}
messagealert(message){
  if(message==='created'){
    gtag('event', 'conversion', {'send_to': 'AW-754521987/Kk-7CJixwuEBEIOv5OcC'})
    this.successAlert()
  }else{
 
    this.existAlert()
  }

}



handleResponse(response) {

  return response.text().then(text => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
          
       // const error = (data && data.message);
        return 'error'
      }else{
      

      }
     

      return data;
  });
}


handleValidate(e) {

    
  this.state.password.length< 8
? this.setState({
passwordError: (
<small className="text-danger">Password must be at least 8 characters long</small>
)
})
: this.state.password.search(/\d/) == -1
? this.setState({
passwordError: (
   <small className="text-danger">Password must contain 1 number</small>
 )
   })
   : this.state.password.search(/[a-zA-Z]/) == -1
? this.setState({
passwordError: (
   <small className="text-danger">Password must contain 1 letter</small>
 )
   })
  : this.setState({ passwordError: null });

  
  this.state.password2==""
  ? this.setState({
   password2Error: (
       <small className="text-danger">Please confirm your password.</small>
     )
       })
  :this.state.password2.length< 8
  ? this.setState({
    password2Error: (
        <small className="text-danger">Password Must be at least 8 characters long</small>
      )
        })
        : this.state.password2.search(/\d/) == -1
        ? this.setState({
          password2Error: (
              <small className="text-danger">Password must contain 1 number</small>
            )
              })
              : this.state.password2.search(/[a-zA-Z]/) == -1
        ? this.setState({
          password2Error: (
              <small className="text-danger">Password must contain 1 letter</small>
            )
              })
              : this.state.password != this.state.password2
              ? this.setState({
                password2Error: (
                    <small className="text-danger">Password must match</small>
                  )
                    })
             : this.setState({ password2Error: null });

             var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
             re.test(this.state.email) === false
               ? this.setState({
                emailError: (
                     <small className="text-danger">
                       This must be a valid email address.
                     </small>
                   )
                 })
               : this.setState({ emailError: null });


               this.state.firstname=== ""
               ? this.setState({
                 firstnameError: (
                     <small className="text-danger">field is  required.</small>
                   )
                     })
               : this.setState({ firstnameError: null });


               this.state.lastname=== ""
               ? this.setState({
                lastnameError: (
                     <small className="text-danger">field is  required.</small>
                   )
                     })
               : this.setState({ lastnameError: null });

               this.state.company=== ""
               ? this.setState({
                companyError: (
                     <small className="text-danger">field is  required.</small>
                   )
                     })
               : this.setState({ companyError: null });
         

               this.state.verified===false
               ? this.setState({
                reCaptchaError: 'notVerified'
                     })
               : this.setState({ reCaptchaError: null });

                  return
}

  render() {
    const {  firstname,lastname,company,email,password,password2,alert,disabledbtn } = this.state;
   
    return (
     
      <Grid> {alert}
        <Row>
          <Col md={8} mdOffset={2}>
            <div className="header-text">
           
            </div>
          </Col>
          <Col md={4} mdOffset={2}>
          <div
            className={"header" + (this.props.textCenter ? " text-center" : "")}
          >
         <img src={logo} alt="SnappyWire"  className="loginLogo" />
        
          </div>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="far fa-user" />
                
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Accept Crypto Payment</Media.Heading>
                Enjoy a quick, easy, and cost-effective way for your business to receive crypto payments from customers without the risk of volatility exposure.
              </Media.Body>
            </Media>
            <Media>
              <Media.Left>
                <div className="icon">
                <i className="fas fa-globe-americas" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Global Reach</Media.Heading>
                Reach new markets instantly. 
              </Media.Body>
            </Media>
            <Media>
              <Media.Left>
                <div className="icon">
                  <i className="fas fa-cogs" />
                </div>
              </Media.Left>
              <Media.Body>
                <Media.Heading>Our Technology</Media.Heading>
                Leveraging our frictionless, blockchain-based payment processing technology, your cryptocurrency will automatically and instantaneously be converted to USD currency and then deposited into your bank account.
              </Media.Body>
            </Media>
          </Col>
          <Col md={4}>
         <div className="formTop"><h2>Create your SnappyWire account</h2>
Already have an account? <a href="/#/a/login-page" >Sign in.</a></div>
            <form>
              <Card
                plain
                content={
                  <div>
                    
                    <FormGroup>
                      <FormControl type="text" 
                      
                      name="firstname"
                      value={firstname}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}

                      placeholder="Your First Name" />
                       {this.state.firstnameError}
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="text" 
                    name="lastname"
                        onBlur={this.handleValidate}
                        onChange={this.handleChange}

                        value={lastname}
                      placeholder="Your Last Name" />
                         {this.state.lastnameError}
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="text" 
                           value={company}
                      name="company"
                      onBlur={this.handleValidate}
                      onChange={this.handleChange}
                      
                      placeholder="Company / Full Name" />
                        {this.state.companyError}
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="email" 
                           value={email}
                      name="email"
                      onBlur={this.handleValidate}
                      onChange={this.handleChange}
                      placeholder="Enter Email" />
                        {this.state.emailError}
                    </FormGroup>
                    <FormGroup>
                      <FormControl type="password"
                                              value={password}
                      name="password"
                      onBlur={this.handleValidate}
                      onChange={this.handleChange}
                      placeholder="Password" />
                        {this.state.passwordError}
                    </FormGroup>
                    <FormGroup>
                      <FormControl
                        type="password"
                        value={password2}
                        name="password2"
                        onBlur={this.handleValidate}
                        onChange={this.handleChange}


                        placeholder="Password Confirmation"
                      />
                        {this.state.password2Error}
                    </FormGroup>
                    <FormGroup><Reaptcha sitekey="6LfeqagZAAAAAEJMZCMzFhNsI29OgJHIFig3TF1g" onVerify={this.onVerify} />
                
                  </FormGroup>
                    
                  </div>
                }
                ftTextCenter
                legend={
!disabledbtn
                 ?<Button wd fill neutral  
                  onClick={() => this.handleSubmit()}>
                    Create your account
                  </Button>
                  
                  :<Button wd fill neutral  
                  disabled>
                    Create your account
                  </Button>
                }
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default RegisterPage;
