import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,  Tooltip,
  OverlayTrigger
} from "react-bootstrap";


import Datetime from "react-datetime";
import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Select from "react-select";

import "react-select/dist/react-select.css";
const moment = require('moment');
const Config = require('config');



class InvoiceInfo extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      total:0,
      error:false,
      loading: true,
      alert: null,
     items:[{"item":"","cost":"","quantity":""}],
     duedate: moment().add(30,'days').format('MM/DD/YYYY'),
     customerAccounts:[],
     actiondate:'',
     duedateError:null,
     totalError:null,
      newinvoice:true,
      customer_id:null,
      customer_name:null,
  itemsError:null,
      refid:'',
      status:'',
      customer_idError:null,
  invid:null,
invidcheck:true,
  transactions:[],
  selectData:[],
  MinInvoice:Config.MinInvoice,
  currency:'USD',
  isSubmitted:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleValidate = this.handleValidate.bind(this);
    this.addItem = this.addItem.bind(this);

 
  }

  componentWillMount(){
    var selectData=JSON.parse(localStorage.getItem('assets'));
 this.props.match.params.invid!='new'
   ? this.setState({invid:this.props.match.params.invid,newinvoice:false,selectData:selectData},this.getcustomers, this.getinvoice(this.props.match.params.invid),this.getinvoicetransactions(this.props.match.params.invid))
   : this.setState({loading: false,newinvoice:true,selectData:selectData},this.getcustomers)
   this.updateTotal();
  
  
  }



getinvoicetransactions(invid){


  var user=JSON.parse(localStorage.getItem('user'));

  const requestOptions = {
    method: 'get',
    headers: { 'Content-Type': 'application/json','access-token': user.token,'mid':user.data.merchant_id}
 
};



return fetch(Config.API+`/api/invoice/transactions/`+invid,requestOptions)
  .then( this.handleResponse)
  .then( transactiondata => this.setState({transactions:transactiondata.data, loading: false}))};






  getinvoice(invid){

    var user=JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
      headers: { 'Content-Type': 'application/json' ,'access-token': user.token,'mid':user.data.merchant_id },
    };

    fetch(Config.API+'/api/invoice/'+invid,requestOptions)
    .then( this.handleResponse)
     .then( invoice=> this.setState({
  
        items:JSON.parse(invoice.data.items),
      total:invoice.data.total_amount,
        refid:invoice.data.ref_id,
        customer_id:invoice.data.customer_id,
        customer_name:invoice.data.display_name,
        status:invoice.data.status,
        duedate:moment(invoice.data.due_date).format("MM/D/YYYY"),
        actiondate:invoice.data.action_date,
        currency:invoice.data.currency,
      invid_id:this.props.match.params.invid, loading: false}));

     
  
    }



    checkinvoiceid(refid){

  if(refid!=''){
      var user=JSON.parse(localStorage.getItem('user'));
      var merchant_id=user.data.merchant_id;
      var token=user.token;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':merchant_id},
               body: JSON.stringify({refid})
            };



      fetch(Config.API+'/api/invoice/check',requestOptions)
      .then( this.handleResponse)
       .then( check=> check.message=='fail'
       ? this.setState({invidcheck:false})
       :this.setState({invidcheck:true}));
  
       
          }else{this.setState({invidcheck:true})}
      }




 
handleCustomerSelectChange = (data)=> {
  if(data!==null){
  this.setState({ customer_id:data.value});}
 
};



handleCurrencySelectChange = (data)=> {
  if(data!==null){
  this.setState({ currency:data.value});}
 
};



    updateinvoiceinfo(customer_id,items,duedate,refid,total_amount,status,invoice_id,currency){
 
      var user=JSON.parse(localStorage.getItem('user'));
var merchant_id=user.data.merchant_id;
var token=user.token;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id},
         body: JSON.stringify({customer_id,items,duedate,refid,total_amount,status,invoice_id,currency})
      };
         
    
      return fetch(Config.API+'/api/invoice/update',requestOptions)
     .then( this.handleResponse)
     .then(status=='Sent' ? this.props.handleClick("tr","success","Invoice has been saved and sent",<span data-notify='icon' className='pe-7s-check' />):this.props.handleClick("tr","success","Invoice has been saved as draft",<span data-notify='icon' className='pe-7s-check' />))
     .then(()=>this.setState({status:status,newstatus:'',isSubmitted:false}))
    
  }


  saveinvoiceinfo(customer_id,items,duedate,refid,total_amount,status,currency){

    var user=JSON.parse(localStorage.getItem('user'));
    var merchant_id=user.data.merchant_id;
  
var token=user.token;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id},
       body: JSON.stringify({customer_id,items,duedate,refid,total_amount,status,currency})
    };
        

    return fetch(Config.API+'/api/invoice/create',requestOptions)
   .then( this.handleResponse)
   .then( invoice => this.setState({newinvoice:false, invid:invoice.data.invoice_id,refid:invoice.data.ref_id,update:true}))
   .then(status=='Sent' ? this.props.handleClick("tr","success","Invoice has been saved and sent",<span data-notify='icon' className='pe-7s-check' />):this.props.handleClick("tr","success","Invoice has been saved as draft",<span data-notify='icon' className='pe-7s-check' />))
   .then(()=>this.setState({status:status,newstatus:'',isSubmitted:false}))
   
}


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
          //console.log(data.status)
          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'
          }
        

        }
       

        return data;
    });
}

getcustomers(){
  var user=JSON.parse(localStorage.getItem('user'));
  var mid=user.data.merchant_id;
   
  const requestOptions = {
    headers: { 'access-token': user.token,'mid':user.data.merchant_id },
  };

  fetch(Config.API+'/api/customers/',requestOptions)
  .then( this.handleResponse)
    .then( customers => this.setState({customerAccounts:customers.data,loading: false}));

   

  }

    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }



      
  handleChangeDate(e) {

    this.setState({duedate:e})

}






   
  handleValidate(e) {



             
    this.state.total<this.state.MinInvoice
    ? this.setState({
      totalError: (
      <small className="text-danger">the invoice total amount must be {this.state.MinInvoice}.00 or more</small>
       )
         })
        : this.setState({ totalError: null });

       
        
        this.state.total>250000
        ? this.setState({
          totalError: (
          <small className="text-danger">the invoice maximum limit is $250,000.00</small>
           )
             })
            : this.setState({ totalError: null });

            
    this.state.customer_id==null
    ? this.setState({
      customer_idError: (
         <small className="text-danger">this field is required</small>
       )
         })
        : this.setState({ customer_idError: null });


               
        this.state.duedate==""
        ? this.setState({
       duedateError: (
             <small className="text-danger">a due date is required</small>
           )
             })
            : moment(this.state.duedate, 'MM/DD/YYYY', true).isValid()
            ?  this.setState({ duedateError: null
          
                    })
            : this.setState({     duedateError: (
              <small className="text-danger">a valid date is required</small>
            ) });


      
               

                    return
}

  handleSubmit(e) {
    e.preventDefault();

    const {customer_id,items,duedate,refid,invid,total,newstatus,currency,invidcheck} = this.state;
 
    if(this.state.newinvoice){if(this.state.duedateError === null  && this.state.customer_idError === null  && this.state.totalError === null && this.state.invidcheck==true ){
      this.setState({isSubmitted:true})
      console.log(JSON.stringify(items))
        this.saveinvoiceinfo(customer_id,JSON.stringify(items),duedate,refid,total,newstatus,currency)
    
    }}else{
        
 if(this.state.duedateError === null   && this.state.customer_idError === null  && this.state.totalError === null ){
 
  this.setState({isSubmitted:true})
   this.updateinvoiceinfo(customer_id,JSON.stringify(items),duedate,refid,total,newstatus,invid,currency)}}

   }


   onChangeQty(e, i){
    const { value } = e.target;
    const {items} = this.state;

    isNaN(value)
    ?items[i].quantity =  0
    :items[i].quantity = value
    this.setState({
     items
    })
    this.updateTotal()
   }

   onChangePrice(e, i){
    const { value } = e.target;
    const {items} = this.state;

    isNaN(value)
    ?items[i].cost =  0
    :items[i].cost =value
    this.setState({
     items
    })
    this.updateTotal()
   }



   updateTotal(){
     let total=0;
    this.state.items.map((item, i) => 
    total=total+(item.quantity*item.cost)
    )
   this.setState({total:Number(total).toFixed(2)})
   }


   onChangeItem(e, i){
    const { value } = e.target;
    const {items} = this.state;


    items[i].item =  value;
    this.setState({
     items
    })
    
   }


addItem(e){
  e.preventDefault();
  const {items} = this.state;
  var newStateArray = items.slice();

  newStateArray.push({"item":"","cost":'',"quantity":''});
  this.setState({items: newStateArray});
}


removeItem(i){
  var array = this.state.items;
  array.splice(i, 1)


  this.setState({
   items: array
  },   this.updateTotal())
  
}


   render() {
  const { isSubmitted,currency,email,newinvoice,total,duedate,customer_id,customerAccounts,refid,customer_name,actiondate,selectData,invidcheck} = this.state; 


  var assets=selectData.data
    

function getexplorer(assetid){
var asseturl = assets.find(items => { return items.asset_id === ''+assetid+''})
return asseturl.explorer_url

}

    const CustomerOptions = customerAccounts.map(v => ({
      label: v.display_name,
      value: v.customer_id
    }));

    var CurrencyOptions = [{ value: 'USD', label: 'USD'},{ value: 'CAD', label: 'CAD'},{ value: 'EUR', label: 'EUR'}];
  
        

    
   
if (this.state.loading) return <Loader />
      return (
      <div className="main-content">
                      
        <Grid fluid>
   
          <Row>
      <Col lg={12}>  <h3>Invoice</h3> </Col>
            <Breadcrumb>
            <Breadcrumb.Item href="/#/billing">Billing</Breadcrumb.Item>
            <Breadcrumb.Item  href="/#/billing/invoices">Invoices</Breadcrumb.Item>
            <Breadcrumb.Item active>Invoice Details</Breadcrumb.Item>
          </Breadcrumb>
                    <Col md={12}> 
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
                  title={"Invoice "+refid}
                  content={
                    <div>
           {this.state.newinvoice
          ? <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Invoice ID <OverlayTrigger
        key='top'
        placement='top'
        overlay={
          <Tooltip id={`tooltip-top`}>
   If not entered an incremental value will be used.
   The value must be a unique identifier.
          </Tooltip>
        }
      >
       <i className="fad fa-exclamation-circle"></i>
      </OverlayTrigger>{' '}
                        </Col>
                        <Col  sm={6}>
                       <FormControl
                            type="text"
                            name="refid"
                          value={refid}
                            onBlur={()=>{this.checkinvoiceid(refid)}}
                            onChange={this.handleChange}
                          />
                        
{invidcheck
?''
: <small className="text-danger">The Invoice ID has already been used. please enter a unique ID</small>}
                    
                        
                        </Col>
                        </FormGroup>
                        :''}

              <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                Customer
                        </Col>
                        <Col sm={6}>
                          
<Select
                          isClearable={false}
                      
                          name="customer_id"
                          value={customer_id}
                          options={CustomerOptions}
                          onChange={this.handleCustomerSelectChange}
                        />
                

{this.state.customer_idError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                Currency
                        </Col>
                        <Col sm={6}>
                          
<Select
                          isClearable={false}
                      
                          name="currency"
                          value={currency}
                          options={CurrencyOptions}
                          onChange={this.handleCurrencySelectChange}
                        />
                


                        </Col>
                      </FormGroup>
                      
    
                        <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Due Date
                        </Col>
                        <Col  sm={6}>
                        <Datetime
                       
                       onChange={this.handleChangeDate}
                        timeFormat={false}
                        inputProps={{  name:"duedate",placeholder: "Date Picker Here" }}
                        defaultValue={duedate}
                      />

                    
                         {this.state.duedateError}
                        </Col>


                      </FormGroup>

                      
                      <FormGroup controlId="formHorizontalRequiredText">
                      <Col  sm={12}><Col  xs={4} sm={4}>ITEM</Col> <Col xs={2}  sm={2}>PRICE ({this.state.currency})</Col> <Col xs={2}   sm={2}>QUANTITY</Col><Col xs={2}   sm={2}></Col><Col xs={2}   sm={2}>Total ({this.state.currency}) </Col></Col>
                       
                        { this.state.items.map((item, i) => 
                           <Col  sm={12}><div className="invoiceItem"><Col xs={4}  md={4}> <div className="itemHolder" ><input type="text" id="formHorizontalRequiredText" className="form-control" value={item.item}  onChange={(e)=>this.onChangeItem(e,i)}  /></div>
                        </Col>
                        <Col  xs={2}  md={2}><div className="priceHolder" ><input type="text" id="formHorizontalRequiredText"  className="form-control" value={item.cost}  onChange={(e)=>this.onChangePrice(e,i)} /></div>
                        </Col>
                        <Col  xs={2}  md={2}><div className="quantityHolder " ><input type="text" id="formHorizontalRequiredText" className="form-control"  value={item.quantity}     onChange={(e)=>this.onChangeQty(e,i)}/></div>
                        </Col>
                       
                        <Col  xs={2} md={2}> {i!==0 
                        ?<Button
                        fill
                        bsStyle="info"
                        type="submit"
                      
                        onClick={() => this.removeItem(i)}
                      >
                  <i class="fas fa-trash-alt"></i>
                      </Button>
                      :<div/>
}
                      </Col> <Col xs={2} md={2}><div className="amountHolder" >{Number(item.quantity*item.cost).toFixed(2)}</div></Col>
                        </div>
                        </Col>   ) } 
                         
                        <Col  sm={12}>      <div className="invoiceItem">  
                        <Col xs={12} md={10}>  <Button
                      fill
                      bsStyle="info"
                      type="submit"
                    
                      onClick={this.addItem}
                    ><i class="fas fa-plus"></i> Item
                    </Button>
                    </Col> <Col  xs={2}  md={2}></Col> </div> </Col>
                    <Col  sm={12}  md={12}>    <div className="invoiceItem">  
                        <Col xs={12} md={7}> {this.state.totalError}</Col>
                        <Col xs={12} md={3}>        </Col>
                     <Col  xs={12}  md={2}>
                     Total {Number(total).toFixed(2)} {this.state.currency}<br/>
            
                     </Col>  </div> </Col>
                     </FormGroup>
                    </div>
                  }
                  legend={
                  <div>
                    {this.state.status!='Sent'
                    ?<Button
                      fill
                      bsStyle="fill"
                      type="submit"
                      disabled={isSubmitted}
                      onClick={()=>this.setState({newstatus:'Draft'},this.handleValidate)}
                    >
                      Save as Draft
                    </Button>
                    :<div></div>
                    } <Button
fill
bsStyle="info"
type="submit"
disabled={isSubmitted}
onClick={()=>this.setState({newstatus:'Sent'},this.handleValidate)}
>
Save & Send
</Button></div>  
                    
                  }
      
                />
              </Form>
            </Col>
                 
                  </Row>
                  {this.state.status=='Sent'
                    ?

                  <Row><Col sm={12}>
                  <div className="card">
                    <div className="header"><div className="title">Transactions</div></div>
                    <div className="content">

                <table><thead><tr><td>Date</td><td>Status</td><td>Price ({this.state.currency})</td><td>Coin</td><td>Amount</td><td>Paid ({this.state.currency})</td><td>Tx Id</td></tr></thead><tbody>{ this.state.transactions.map((transaction, i) => <tr key={i}><td>{moment(transaction.date_time).format("MM/DD/YYYY H:m:s")}</td><td>{transaction.status}</td><td>{transaction.price} </td><td>{transaction.coin}</td><td>{transaction.payment_amount}</td><td>{Number(transaction.total_payment).toFixed(2)}</td><td><a href={getexplorer(transaction.coin)+transaction.txid}
                  
                  target="_blank">View transaction</a></td></tr>)}</tbody></table>
                  
</div>
                  </div>
                  </Col></Row>

                :<div></div>}
               
        </Grid>
      </div>
    );
  }
}

export default InvoiceInfo ;
