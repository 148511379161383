import React, { Component } from "react";
import logo from "snappywireb.svg";
const Config = require('config');
class Login extends Component {
  render() {
    return (   <div> <div
            className={"header" + (this.props.textCenter ? " text-center" : "")}
          > 
         <img src={logo} alt="SnappyWire"  className="loginLogo" />
        
          </div>
      <div
        className={
          "card" +
          (this.props.hidden ? " card-hidden" : "") +
          (this.props.calendar ? " card-calendar" : "") +
          (this.props.plain ? " card-plain" : "") +
          (this.props.wizard ? " card-wizard" : "")
        }
      >
    
      
      
        <div
          className={
            "content" +
            (this.props.ctAllIcons ? " all-icons" : "") +
            (this.props.ctFullWidth ? " content-full-width" : "") +
            (this.props.ctTextCenter ? " text-center" : "") +
            (this.props.tableFullWidth ? " table-full-width" : "")
          }
        >
          {this.props.content}
        </div>
        {this.props.stats !== undefined || this.props.legend !== undefined ? (
          <div
            className={
              "footer" + (this.props.ftTextCenter ? " text-center" : "")
            }
          >
            {this.props.legend !== undefined ? (
              <div className="legend">{this.props.legend}</div>
            ) : null}
            {this.props.stats !== undefined ? <hr /> : null}
            {this.props.stats !== undefined ? (
              <div className="stats">{this.props.stats}</div>
            ) : null}
          </div>
        ) : null}
      </div>
      
      {Config.ShowSignup
      ?<div dangerouslySetInnerHTML={{__html: 'Don`t have an account?  <a href="'+Config.SignupUrl+'"  >Sign up</a></div>'}} />
      :''
     }
     </div>
    );
  }
}

export default Login;
